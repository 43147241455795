<template>
  <b-modal
      v-model="visibleModal"
      title="Wybierz kraj do utworzenia korekty faktury"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <div v-for="(invoice, index) in invoiceVats" :key="index">
        <div class="row">
          <div class="col-12">
            <a @click="select(invoice)" class="clickable-element" target="_blank" style="font-size: 64px;">
              <span :class="`fi fi-${invoice.language}`" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {

  data() {
    return {
      manual: false,
      invoiceVats: [],
      visibleModal: false,
    }
  },

  methods: {
    openModal(manual, invoiceVats, invoiceCorrections) {
      this.manual = manual
      this.invoiceVats = invoiceVats.filter(first => !invoiceCorrections.some(second => first.language === second.language))
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.invoiceVats = []
      this.manual = false
    },

    select(invoice) {
      this.$emit("select", this.manual, invoice.language)
      this.hideModal()
    }
  }

}
</script>