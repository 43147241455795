<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4>Logi opłat zamówienia przez operatora</h4>

        <ecat-table
            :table="table"
            :fields="getFields()"
            :items="loadOrderFeesLogs"
            :pagination-top="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {productsHelper} from "@/helpers/products-helper";
import {dateUtil} from "../../../helpers/date-util";
import {priceHelper} from "../../../helpers/price-helper";

export default {
  name: "order-fees-logs-table",

  props: {
    shopOrderId: {
      type: String,
      required: true
    },

    market: {
      type: Object,
      required: true
    }
  },

  computed: {
    productsHelper() {
      return productsHelper
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      }
    }
  },

  methods: {
    getFields() {
      return [
        {key: "transactionId", label: "ID Transakcji"},
        {key: "transactionEmail", label: "Adres e-mail"},
        {key: "transactionPaid", label: "Kwota", formatter: price => priceHelper.format(price, this.market.currency) },
        {key: "transactionDate", label: "Data wykonania transakcji", formatter: value => dateUtil.asDateTime(value)}
      ]
    },

    async loadOrderFeesLogs() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/tpay/shop-order/${this.shopOrderId}/logs`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            shopOrderId: this.shopOrderId,
            page: page,
            size: this.table.perPage
          }
        });

        this.table.items = data.payments
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        this.table.items = []
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    }
  }

}
</script>