<template>
  <b-modal
      v-model="visibleModal"
      title="Faktury"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <div v-for="(invoice, index) in invoices" :key="index">
        <div class="row">
          <div class="col-12 col-lg-3">
            <a :href="invoice.previewUrl" target="_blank" style="font-size: 64px;">
              <span :class="`fi fi-${invoice.language}`" />
            </a>
          </div>

          <div class="col-12 col-lg-9 py-4">
            <ecat-button v-if="deleteButton" :callback="() => deleteInvoice(invoice)" variant="danger" message="Usuń" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {toastHelper} from "../../../helpers/toast-helper";
import {errorCatcher} from "../../../helpers/error-catcher";

export default {

  data() {
    return {
      invoices: [],
      visibleModal: false,
      deleteButton: false,
      invoiceType: "",
      callback: null
    }
  },

  methods: {
    openModal(invoices) {
      this.invoices = invoices
      this.visibleModal = true
    },

    openModalWithDeleteButtonAndCallback(invoices, invoiceType, callback) {
      this.openModal(invoices)
      this.invoiceType = invoiceType
      this.deleteButton = true
      this.callback = callback
    },

    hideModal() {
      this.visibleModal = false
      this.invoices = []
      this.deleteButton = false
      this.invoiceType = ""
      this.callback = null
    },

    async deleteInvoice(invoice) {
      try {
        await axios.delete(`/invoice/${invoice.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })
        let message = 'Poprawnie usunięto fakturę'
        if (this.invoiceType === 'CORRECTION') {
          message = 'Poprawnie usunięto korektę faktury'
        }

        toastHelper.success(message)
        await this.callback()
        this.hideModal()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  }

}
</script>