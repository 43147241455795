<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4>Logi zamówienia</h4>

        <ecat-table
            ref="table"
            :table="table"
            :fields="getFields()"
            :items="loadOrderLogs"
            :pagination-top="true">
          <template v-slot:productId="{ item }">
            <span>{{ item.productId && getProduct(item.productId) ? getProduct(item.productId).name : 'Nie dotyczy' }}</span>
          </template>

          <template v-slot:oldValue="{ item }">
            <template v-if="!item.oldValue">
              <span class="badge badge-soft-danger">Brak</span>
            </template>
            <template v-else-if="item.valueType === 'BOOLEAN'">
              <span class="badge" :class="item.oldValue ? 'badge-soft-success' : 'badge-soft-danger'">{{ $t(item.oldValue ? 'message.yes' : 'message.no' )}}</span>
            </template>
            <template v-else-if="item.valueType === 'ORDER_STATUS'">
              <span>{{ $t('core-orders.order.status.' + item.oldValue) }}</span>
            </template>
            <template v-else-if="item.valueType === 'PRODUCT_STATUS'">
              <span :class="orderHelper.getProductStatusColor(item.oldValue)">{{ $t('core-orders.product.status.' + item.oldValue) }}</span>
            </template>
            <template v-else-if="item.valueType === 'TAX'">
              <span>{{ getTax(item.oldValue) ? getTax(item.oldValue).rate + '%' : '' }}</span>
            </template>
            <template v-else>
              {{ item.oldValue }}
            </template>
          </template>

          <template v-slot:newValue="{ item }">
            <template v-if="!item.newValue">
              <span class="badge badge-soft-danger">Brak</span>
            </template>
            <template v-if="item.valueType === 'BOOLEAN'">
              <span class="badge" :class="item.newValue ? 'badge-soft-success' : 'badge-soft-danger'">{{ $t(item.newValue ? 'message.yes' : 'message.no' )}}</span>
            </template>
            <template v-else-if="item.valueType === 'ORDER_STATUS'">
              <span>{{ $t('core-orders.order.status.' + item.newValue) }}</span>
            </template>
            <template v-else-if="item.valueType === 'TAX'">
              <span>{{ getTax(item.newValue) ? getTax(item.newValue).rate + '%' : '' }}</span>
            </template>
            <template v-else-if="item.valueType === 'PRODUCT_STATUS'">
              <span :class="orderHelper.getProductStatusColor(item.newValue)">{{ $t('core-orders.product.status.' + item.newValue) }}</span>
            </template>
            <template v-else>
              {{ item.newValue }}
            </template>
          </template>

          <template v-slot:userId="{ item }">
            <span>{{ item.userId ? getUserName(item.userId) : 'Nie dotyczy' }}</span>
          </template>
        </ecat-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {productsHelper} from "@/helpers/products-helper";
import {dateUtil} from "../../../helpers/date-util";
import {orderHelper} from "../../../helpers/order-helper";

export default {
  name: "order-logs-table",

  props: {
    shopOrderId: {
      type: String,
      required: true
    }
  },

  computed: {
    orderHelper() {
      return orderHelper
    },

    productsHelper() {
      return productsHelper
    }
  },

  data() {
    return {
      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        items: [],
        isBusy: false
      },

      shopOrderProducts: new Map(),
      users: new Map(),
      taxes: new Map()
    }
  },

  methods: {
    getFields() {
      return [
        { key: "productId", slot: true, label: "Nazwa produktu" },
        { key: "description", label: "Opis" },
        { key: "oldValue", slot: true, label: "Stara wartość" },
        { key: "newValue", slot: true, label: "Nowa wartość" },
        { key: "createdAt", label: "Utworzono", formatter: value => dateUtil.asDateTime(value) },
        { key: "userId", slot: true, label: "Zmieniający" }
      ]
    },

    async loadOrderLogs() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/shop-order/log/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            shopOrderId: this.shopOrderId,
            page: page,
            size: this.table.perPage
          }
        });

        this.table.items = data.shopOrderLogs
        this.table.totalRows = data.count;
        this.table.rows = data.count;

        await this.loadUsers()
        await this.loadShopOrderProducts()
        await this.loadTaxes()

      } catch (error) {
        this.table.items = []
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    async loadUsers() {
      if (this.table.items === 0) {
        return;
      }

      const ids = [];
      for (let shopOrderLog of this.table.items) {
        if (shopOrderLog.userId) {
          ids.push(shopOrderLog.userId);
        }
      }

      if (ids.length === 0) {
        return
      }

      const json = {
        ids: ids
      }

      const {data} = await axios.post(`/user`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.users = new Map(data.map((obj) => [obj.id, obj]));
    },

    getUser(userId) {
      if (!this.users) {
        return null
      }

      return this.users.get(userId) || null
    },

    getUserName(userId) {
      const user = this.getUser(userId)
      if (!user) {
        return "-"
      }

      return `${user.firstName} ${user.lastName} (${user.email})`
    },

    async loadShopOrderProducts() {
      if (this.table.items === 0) {
        return;
      }

      const ids = [];
      for (let shopOrderLog of this.table.items) {
        if (shopOrderLog.productId) {
          ids.push(shopOrderLog.productId);
        }
      }

      if (ids.length === 0) {
        return
      }

      const {data} = await axios.get(`/shop-order/product/by-ids`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        params: {
          ids: ids.join(",")
        }
      });

      this.shopOrderProducts = new Map(data.map((obj) => [obj.id, obj]));
    },

    getProduct(productId) {
      if (!this.shopOrderProducts) {
        return null
      }

      return this.shopOrderProducts.get(productId) || null
    },

    async loadTaxes() {
      try {
        if (this.table.items === 0) {
          return;
        }

        const ids = [];
        for (let shopOrderLog of this.table.items) {
          if (shopOrderLog.valueType !== "TAX") {
            continue
          }

          if (shopOrderLog.oldValue) {
            ids.push(shopOrderLog.oldValue)
          }

          if (shopOrderLog.newValue) {
            ids.push(shopOrderLog.newValue)
          }
        }

        if (ids.length === 0) {
          return
        }

        const json = {
          ids: ids
        }

        const {data} = await axios.post(`/tax/list-by-ids`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.taxes = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getTax(taxId) {
      if (!this.taxes) {
        return null
      }

      return this.taxes.get(taxId) || null
    },

    refresh() {
      this.$refs.table.refresh()
    }
  }

}
</script>